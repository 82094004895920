import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormComponent, StepsFormData } from '../form-component.interface';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuestDropdownValues } from '../../../common-components/dropdown/dropdown.component';
import { QuestStepObjectiveType } from 'quest-atlas-angular-components';
import { ModalButton } from '../../../common-components/modal/modal.component';
import { DisplayMapOptions } from 'quest-atlas-angular-components';
import {debounceTime, filter, Subject, take, takeUntil} from 'rxjs';
import {
  updateFormValuesObjectFromIDB
} from '../map-editor/form-component-general.functions';
import { getObjectiveTypeOptions } from '../dropdown-options-for-forms';
import { GeoCoords } from 'quest-atlas-angular-components';
import { readImageFromClipboard } from '../../../functions/file.functions';
import {TranslocoService} from '@ngneat/transloco';
import {IDB_NAME, IDB_VERSION} from '../quest-editor.constants';
import {idbStepsFormStore} from '../quest-editor.functions';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-steps-form',
  templateUrl: './steps-form.component.html',
  styleUrls: ['./steps-form.component.scss']
})
export class StepsFormComponent implements OnInit, FormComponent, OnDestroy {
  readonly MAX_NUMBER_OF_IMAGES = 3;
  readonly QuestStepObjectiveType = QuestStepObjectiveType;
  readonly editorOptions = {theme: 'vs-dark', language: 'json'};

  type = 'steps';
  @Input() formValues: StepsFormData = {};
  form: FormGroup;
  db: IDBDatabase;

  @Input() adaptive = false;
  @Input() cacheFormData = true;
  @Input() userGeo?: GeoCoords;

  stepToRemove = -1;
  editingEncounterStepIndex = -1;
  encounterDataToEdit?: string;

  editingPreconditionStepIndex = -1;
  preconditionDataToEdit?: string;

  modalButtons: ModalButton[] = [
    {
      type: 'secondary',
      text: this.transloco.translate('cancel'),
      onClick: () => {
        this.stepToRemove = -1;
      }
    },
    {
      type: 'error',
      text: this.transloco.translate('delete'),
      onClick: () => {
        this.removeStep(this.stepToRemove);
        this.stepToRemove = -1;
      }
    }
  ];

  encounterEditorModalButtons: ModalButton[] = [
    {
      type: 'secondary',
      text: this.transloco.translate('cancel'),
      onClick: () => {
        this.editingEncounterStepIndex = -1;
      }
    },
    {
      type: 'primary',
      text: this.transloco.translate('save'),
      onClick: () => {
        this.getSteps().at(this.editingEncounterStepIndex).get('encounter')?.setValue(JSON.parse(this.encounterDataToEdit!));
        this.editingEncounterStepIndex = -1;
      }
    }
  ];

  preconditionEditorModalButtons: ModalButton[] = [
    {
      type: 'secondary',
      text: this.transloco.translate('cancel'),
      onClick: () => {
        this.editingPreconditionStepIndex = -1;
      }
    },
    {
      type: 'primary',
      text: this.transloco.translate('save'),
      onClick: () => {
        this.getSteps().at(this.editingPreconditionStepIndex).get('precondition')?.setValue(JSON.parse(this.preconditionDataToEdit!));
        this.editingPreconditionStepIndex = -1;
      }
    }
  ];

  @Output() requestMapOpen = new EventEmitter<{ stepIndex: number; form: any }>();

  objectiveTypeOptions: QuestDropdownValues[] = getObjectiveTypeOptions();
  optionsToChooseFrom = this.objectiveTypeOptions.filter((o) => o.value !== QuestStepObjectiveType.adaptive || this.adaptive);
  mapsOptions: DisplayMapOptions[] = [];

  isUserSuperuser = false;

  destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder, private transloco: TranslocoService, private userService: UserService) {}

  ngOnInit(): void {
    this.userService.getUserInfo$().pipe(take(1), takeUntil(this.destroy$)).subscribe((user) => {
      this.isUserSuperuser = user.isSuperUser;
    });

    if (this.cacheFormData) {
      const request = window.indexedDB.open(IDB_NAME, IDB_VERSION);

      request.onerror = (event) => {
        console.error('IndexedDB error:', event);
      };
      request.onsuccess = (event) => {
        this.db = (event.target as any).result;


        if (!Array.isArray(this.formValues.steps)) {
          updateFormValuesObjectFromIDB(this.formValues, idbStepsFormStore(this.adaptive), this.db).pipe(takeUntil(this.destroy$)).subscribe({
            next: lsv => {
              this.formValues = lsv;

              this.initForm();
            },
            error: () => {
              this.initForm();
            }
          });
        } else {
          this.initForm();
          this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        }
      };
    } else {
      this.initForm()
    }
  }

  private initForm(): void {
    const stepsData = Array.isArray(this.formValues.steps) ? this.formValues.steps : [{}];

    const stepsControls = [];

    for (let i = 0; i < stepsData.length; i++) {
      const sd = stepsData[i];
      const sg = this.fb.group(this.constructStepControls(sd, i));

      sg.get('objectiveType').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.onObjectiveTypeChanged(sg, i));

      stepsControls.push(sg);

      //need some time for the page to render
      setTimeout(() => {
        this.initImageInput(i);
      }, 500);
    }

    this.form = this.fb.group({
      steps: this.fb.array(stepsControls)
    });

    for (let i = 0; i < this.getSteps().length; i++) {
      this.mapsOptions.push(this.getDisplayMapOptions(i));
    }

    this.form.valueChanges
      .pipe(
        debounceTime(500),
        filter(() => this.cacheFormData),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        if (this.db) {
          const v = { steps: new Array<any>() };
          value.steps.forEach((step: any) => {
            v.steps.push(step);
          });

          const objectStore = this.db.transaction(idbStepsFormStore(this.adaptive), 'readwrite').objectStore(idbStepsFormStore(this.adaptive));
          objectStore.clear();
          objectStore.put(value);
        }
      });
  }

  addPhotoClick(index: number): void {
    if (this.getCurrentImages(index).length === this.MAX_NUMBER_OF_IMAGES) {
      return;
    }

    const addPhotoInput = document.getElementById('add-task-imd-input-' + index);
    addPhotoInput?.click();
  }

  removeImgByIndex(stepIndex: number, i: number): void {
    const images = this.getCurrentImages(stepIndex);
    let imagesNew = images.slice(0, i);
    imagesNew = [...imagesNew, ...images.slice(i + 1)];

    this.getSteps().at(stepIndex).get('taskImages')?.setValue(imagesNew);
  }

  isFormValid(): boolean {
    return !!this.form?.valid;
  }

  submitForm(): any {
    return this.form.value;
  }

  getFormData(): any {
    return this.form.value;
  }

  getCurrentImages(index: number): any[] {
    return this.getSteps().at(index).get('taskImages')!.value;
  }

  getSteps(): FormArray {
    return this.form.get('steps') as FormArray;
  }

  addStep(): void {
    const sg = this.fb.group(this.constructStepControls({}, this.getSteps().length));

    sg.get('objectiveType').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.onObjectiveTypeChanged(sg, this.getSteps().length));
    this.getSteps().push(sg);
    this.mapsOptions.push(this.getDisplayMapOptions(this.getSteps().length - 1));
  }

  removeStep(index: number): void {
    this.getSteps().removeAt(index);
  }

  askToRemoveStep(index: number): void {
    this.stepToRemove = index;
  }

  openEncounterEditorForStep(index: number): void {
    this.editingEncounterStepIndex = index;

    this.encounterDataToEdit = '';
    if (this.form.get('steps')?.value[index]?.encounter) {
      this.encounterDataToEdit = JSON.stringify(this.form.get('steps')?.value[index]?.encounter, null, 2);
    }
  }

  openPreconditionEditorForStep(index: number): void {
    this.editingPreconditionStepIndex = index;

    this.preconditionDataToEdit = '';
    if (this.form.get('steps')?.value[index]?.precondition) {
      this.preconditionDataToEdit = JSON.stringify(this.form.get('steps')?.value[index]?.precondition, null, 2);
    }
  }

  hasStepAnyMapData(index: number): boolean {
    const stepControl = this.getSteps().at(index);
    return (
      !!stepControl.get('startZone')!.value ||
      (!!stepControl.get('hazardZones')!.value && !!stepControl.get('hazardZones')!.value.length) ||
      !!stepControl.get('objectivesData')!.value
    );
  }

  getDisplayMapOptions(index: number): DisplayMapOptions {
    const stepControl = this.getSteps().at(index);
    const opts: DisplayMapOptions = {};

    if (stepControl.get('objectiveType')?.value?.value === QuestStepObjectiveType.markerFind) {
      opts.markerFind = stepControl.get('objectivesData')?.value?.markerFind;
    } else if (stepControl.get('objectiveType')?.value?.value === QuestStepObjectiveType.markerReach) {
      opts.markerReach = stepControl.get('objectivesData')?.value?.markerReach;
    }

    opts.startZone = stepControl.get('startZone')?.value;
    opts.hazardZones = stepControl.get('hazardZones')?.value;

    return opts;
  }

  clickPastePhoto(stepIndex: number): void {
    if (this.getCurrentImages(stepIndex).length === this.MAX_NUMBER_OF_IMAGES) {
      return;
    }

    readImageFromClipboard()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value) {
          const controlsGroup = this.getSteps().at(stepIndex);

          this.readImage(controlsGroup, value);
        }
      });
  }

  private constructStepControls(sd: any, index: number): any {
    if (this.adaptive) {
      sd.objectiveType = this.objectiveTypeOptions[2];
    }

    return {
      id: [sd.id],
      name: [sd.name ? sd.name : `${this.transloco.translate('step')} ${index + 1}`, [Validators.required]],
      objectiveType: [sd.objectiveType],
      task: [sd.task, sd.objectiveType?.value === QuestStepObjectiveType.markerFind ? [Validators.required] : []],
      taskImages: [sd.taskImages ? sd.taskImages : []],
      finalDescription: [sd.finalDescription],
      startZone: [sd.startZone],
      hazardZones: [sd.hazardZones],
      timer: [sd.timer],
      objectivesData: [sd.objectivesData, [Validators.required]],
      canBeSkipped: [sd.canBeSkipped],
      encounter: [sd.encounter],
      precondition: [sd.precondition]
    };
  }

  private onObjectiveTypeChanged(stepGroup: FormGroup, index: number): (value: any) => void {
    return () => {
      // this is not the best approach and cleaner would be to create a custom field or group validator
      if (stepGroup.get('objectiveType').value?.value === QuestStepObjectiveType.markerFind) {
        stepGroup.get('task').setValidators([Validators.required]);
      } else {
        stepGroup.get('task').clearValidators();
      }
      stepGroup.get('task').updateValueAndValidity();

      stepGroup.get('objectivesData').setValue(null);
      this.mapsOptions.splice(index, 1, this.getDisplayMapOptions(index));
    };
  }

  private initImageInput(index: number): void {
    const addPhotoInput = document.getElementById('add-task-imd-input-' + index);
    addPhotoInput!.addEventListener('change', (event: any) => {
      const files = event?.target?.files;
      if (!files || files.length === 0) {
        console.error('No files uploaded');
        return;
      }

      const controlsGroup = this.getSteps().at(index);

      this.readImage(controlsGroup, files[0]);
    });
  }

  private readImage(controlsGroup: AbstractControl, image: any): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      controlsGroup.get('taskImages')?.setValue([...controlsGroup.get('taskImages')?.value, { base: reader.result, raw: image }]);

      reader.removeAllListeners('load');
    });

    reader.readAsDataURL(image);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
