import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MatToolbarModule} from '@angular/material/toolbar';
import {TranslocoDirective} from '@ngneat/transloco';
import {ActivatedRoute} from '@angular/router';
import {take, zip} from 'rxjs';

@Component({
  selector: 'app-embedded-map',
  standalone: true,
  imports: [
    MatToolbarModule,
    TranslocoDirective
  ],
  templateUrl: './embedded-map.component.html',
  styleUrl: './embedded-map.component.scss'
})
export class EmbeddedMapComponent implements OnInit {
  url: SafeResourceUrl;

  constructor(private ds: DomSanitizer, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    zip(
      this.activatedRoute.queryParams,
      this.activatedRoute.url,
      this.activatedRoute.params
    ).pipe(
      take(1)
    )
      .subscribe(([queryParams, url, params]) => {
        let viewRecordMode = url.some(segment => segment.path.includes('view-record'));
        let th = url.some(segment => segment.path.includes('th'));
        let qh = url.some(segment => segment.path.includes('qh'));
        let coreUrl = environment.embeddableUrl

        if (viewRecordMode) {
          let p = '';

          if (queryParams['qh']) {
            p = `qh=${queryParams['qh']}`;
          } else if (queryParams['th']) {
            p = `th=${queryParams['th']}`;
          }
          coreUrl = `${coreUrl}/view-record?${p}`;
        } else if (th) {
          coreUrl = `${coreUrl}/view-record?th=${params['id']}`;
        }else if (qh) {
          coreUrl = `${coreUrl}/view-record?qh=${params['id']}`;
        } else {
          if (queryParams['quest']) {
            coreUrl = `${coreUrl}?quest=${queryParams['quest']}`;
          } else if (queryParams['place']) {
            coreUrl = `${coreUrl}?place=${queryParams['place']}`;
          }
        }

        console.log('coreUrl', coreUrl);

        this.url = this.ds.bypassSecurityTrustResourceUrl(coreUrl);
    });
  }
}
