<div id="map-container-{{id}}" class="map-container">
  @if (goToMyLocationBtn) {
    <div class="map-button" style="top: 168px" (click)="goToMyLocation()">
      <q-svg-wrapper iconPath="project-icons/my-location"></q-svg-wrapper>
    </div>
  }
  @if (showNavigateToObjectButton) {
    <div class="map-button" style="top: 224px" (click)="navigateToObject.emit()">
      <q-svg-wrapper iconPath="project-icons/location"></q-svg-wrapper>
    </div>
  }
</div>
