import {TimeSpan} from '../interfaces/common.interfaces';

export const timerFromString = (timerStr: string): TimeSpan => {
  if (timerStr) {
    if (timerStr.charAt(2) === ':') {
      if (timerStr.charAt(5) === ':') {
        return {
          hours: Number(timerStr.charAt(0) + timerStr.charAt(1)),
          minutes: Number(timerStr.charAt(3) + timerStr.charAt(4)),
          seconds: Number(timerStr.charAt(6) + timerStr.charAt(7))
        }
      }

      return {
        hours: 0,
        minutes: Number(timerStr.charAt(0) + timerStr.charAt(1)),
        seconds: Number(timerStr.charAt(3) + timerStr.charAt(4))
      }
    }

    if (timerStr.length === 6) {
      return {
        hours: Number(timerStr.charAt(0) + timerStr.charAt(1)),
        minutes: Number(timerStr.charAt(2) + timerStr.charAt(3)),
        seconds: Number(timerStr.charAt(4) + timerStr.charAt(5))
      }
    } else if (timerStr.length === 4) {
      return {
        hours: 0,
        minutes: Number(timerStr.charAt(0) + timerStr.charAt(1)),
        seconds: Number(timerStr.charAt(2) + timerStr.charAt(3))
      }
    }
  }

  return {hours: 0, minutes: 0, seconds: 0};
}

export const cleanUndefinedInTime = (time: TimeSpan): TimeSpan => {
  if (!time) {
    time = {};
  }

  const newTime = {...time};

  if (newTime.seconds === undefined) {
    newTime.seconds = 0;
  }
  if (newTime.minutes === undefined) {
    newTime.minutes = 0;
  }
  if (newTime.hours === undefined) {
    newTime.hours = 0;
  }
  if (newTime.days === undefined) {
    newTime.days = 0;
  }

  return newTime;
};

export const timeSpanToMs = (time: TimeSpan): number => {
  time = cleanUndefinedInTime(time);
  return time.days * 86400000 + time.hours * 3600000 + time.minutes * 60000 + time.seconds * 1000;
};

export const msToTimeSpan = (ms: number): TimeSpan => ({
  days: Math.floor(ms / 86400000),
  hours: Math.floor((ms % 86400000) / 3600000),
  minutes: Math.floor((ms % 3600000) / 60000),
  seconds: Math.floor((ms % 60000) / 1000)
});
