import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
// @ts-ignore
import {v4 as uuidv4} from 'uuid';
// @ts-ignore
import * as mapboxgl from 'mapbox-gl';
import {
  GeoCoords,
  QGraph, QGraphVertex,
  QGraphVertexWithCircle,
  QGraphVertexWithRadius
} from '../../interfaces/geo-and-movement.interfaces';
import {
  addLayersToMap, adjustMapBBox,
  drawMarkerOnMap, drawStaticMarker, getAllCoordinatesFromZone,
  zoneToFeatureLineString,
  zoneToFeaturePolygon
} from '../../functions/map-general.functions';
import {LngLatLike} from 'mapbox-gl';
import * as turf from '@turf/turf';
import {EnvProviderService} from '../../services/env-provider.service';
import {QuestMetadata} from '../../interfaces/quest-core.interfaces';
import {Cluster} from '../../interfaces/cluster.interfaces';
import {Marker, Obelisk} from '../../interfaces/exploration.interfaces';
import {drawObelisk} from '../../functions/exploration.functions';
import {SvgWrapperComponent} from '../svg-wrapper/svg-wrapper.component';
import {DomSanitizer} from '@angular/platform-browser';

export interface DisplayMapOptions {
  quests?: QuestMetadata[];
  clusters?: Cluster[];
  obelisks?: Obelisk[];
  markers?: Marker[];
  center?: GeoCoords;
  zoom?: number;
  markerReach?: QGraphVertex;
  /** @deprecated */
  markerFind?: QGraphVertexWithCircle;
  // not removing markerFind because of the backward compatibility
  markerFindZones?: QGraphVertexWithCircle[];
  hideMarkerInMarkerFindZones?: boolean;
  numberedMarkers?: QGraphVertex[];
  startZone?: QGraph | QGraphVertexWithRadius;
  hazardZones?: QGraph[];
  ignoreFitBounds?: boolean;
}

@Component({
  selector: 'app-display-map',
  templateUrl: './display-map.component.html',
  styleUrls: ['./display-map.component.scss'],
  imports: [
    SvgWrapperComponent
  ],
  standalone: true
})
export class DisplayMapComponent implements OnInit, AfterViewInit, OnDestroy {
  id = uuidv4();

  map!: mapboxgl.Map;
  markers: mapboxgl.Marker[] = [];
  mapLoaded = false;
  observer = new ResizeObserver(entries => {
    this.map.resize();
  });
  allCoordinates: GeoCoords[] = [];

  @Input() goToMyLocationBtn = false;
  @Input() hideControls = false;
  @Input() rememberLastCenter = false;
  @Input() showNavigateToObjectButton = false;

  private questMarkers = new Map<number, mapboxgl.Marker>();
  private clusterMarkers = new Map<number, mapboxgl.Marker>();
  private numberedMarkers = new Map<string, mapboxgl.Marker>();

  private _options?: DisplayMapOptions;


  get options(): DisplayMapOptions {
    return this._options;
  }

  @Input()
  set options(value: DisplayMapOptions) {
    if (!value) {
      return;
    }

    this._options = {...value};

    if (!this._options.markerFindZones) {
      this._options.markerFindZones = [];
    }

    if (this._options.markerFind) {
      this._options.markerFindZones = [this._options.markerFind, ...this._options.markerFindZones];
    }

    if (!this.mapLoaded) {
      return;
    }

    this.cleanUpFeatures();
    this.drawFeatures();
  }

  @Output() mapInit = new EventEmitter<mapboxgl.Map>();
  @Output() clickOnQuest = new EventEmitter<QuestMetadata>();
  @Output() clickOnCluster = new EventEmitter<Cluster>();
  @Output() clickOnObelisk = new EventEmitter<Obelisk>();
  @Output() clickOnMarker = new EventEmitter<Marker>();
  @Output() navigateToObject = new EventEmitter<void>();

  @Output() mapMove = new EventEmitter<mapboxgl.Map>();
  @Output() mapZoom = new EventEmitter<mapboxgl.Map>();
  @Output() mapMoveEnd = new EventEmitter<mapboxgl.Map>();

  constructor(private envs: EnvProviderService, private ds: DomSanitizer) {
  }

  ngOnInit(): void {
    if (!this._options) {
      this._options = {};
    }
  }

  ngAfterViewInit(): void {

    let center: [number, number] = [10, 10];

    if (this._options?.markerFind) {
      center = [this._options.markerFind.coords.lng, this._options!.markerFind.coords.lat];
    } else if (this._options?.markerReach) {
      center = [this._options.markerReach.coords.lng, this._options!.markerReach.coords.lat];
    } else if (this._options?.center) {
      center = [this._options.center.lng, this._options.center.lat];
    } else {
      navigator.geolocation.getCurrentPosition(position => {
        this.map.setCenter([position.coords.longitude, position.coords.latitude]);
        this.mapMoveEnd.emit(this.map);
      });
    }

    this.map = new mapboxgl.Map({
      accessToken: this.envs.environment.mapboxPublicToke,
      container: `map-container-${this.id}`,
      style: 'mapbox://styles/mapbox/outdoors-v12',
      center,
      zoom: this.options.zoom ?? 14
    });

    if (!this.hideControls) {
      this.map.addControl(
        new MapboxGeocoder({
          accessToken: this.envs.environment.mapboxPublicToke,
          mapboxgl: mapboxgl,
        })
      );

      this.map.addControl(new mapboxgl.NavigationControl());
    }

    this.mapInit.emit(this.map);

    this.map.on('load', () => {
      this.mapLoaded = true;

      addLayersToMap(this.map);

      this.drawFeatures();

      if (this.rememberLastCenter) {
        const lastCenter = localStorage.getItem('lastCenter');
        if (lastCenter) {
          this.map.setCenter(JSON.parse(lastCenter));
        }
      }

      this.map.on('move', () => this.mapMove.emit(this.map));
      this.map.on('zoom', () => this.mapZoom.emit(this.map));
      this.map.on('moveend', () => this.mapMoveEnd.emit(this.map));
      this.map.on('moveend', () => {
        if (this.rememberLastCenter) {
          localStorage.setItem('lastCenter', JSON.stringify(this.map.getCenter()));
        }
      });
    });

    this.observer.observe(document.getElementById(`map-container-${this.id}`)!);
  }

  goToMyLocation(): void {
    navigator.geolocation.getCurrentPosition(position => {
      this.map.flyTo({
        center: [position.coords.longitude, position.coords.latitude],
      });
    });
  }

  private cleanUpFeatures(): void {
    this.questMarkers.forEach(value => value.remove());
    this.questMarkers.clear();

    this.clusterMarkers.forEach(value => value.remove());
    this.clusterMarkers.clear();

    this.numberedMarkers.forEach(value => value.remove());
    this.numberedMarkers.clear();

    this.markers.forEach(value => value.remove());
    this.markers = [];
    this.allCoordinates = [];

    (this.map.getSource('task-zone') as mapboxgl.GeoJSONSource).setData({
      type: 'FeatureCollection',
      features: []
    });
    (this.map.getSource('task-zone-outline') as mapboxgl.GeoJSONSource).setData({
      type: 'FeatureCollection',
      features: []
    });

    (this.map.getSource('start-zones') as mapboxgl.GeoJSONSource).setData({
      type: 'FeatureCollection',
      features: []
    });
    (this.map.getSource('start-zone-outline') as mapboxgl.GeoJSONSource).setData({
      type: 'FeatureCollection',
      features: []
    });

    (this.map.getSource('hazard-zones') as mapboxgl.GeoJSONSource).setData({
      type: 'FeatureCollection',
      features: []
    });
    (this.map.getSource('hazard-zones-outline') as mapboxgl.GeoJSONSource).setData({
      type: 'FeatureCollection',
      features: []
    });
  }

  private drawFeatures(): void {
    if (this.options.center) {
      this.map.setCenter([this.options.center.lng, this.options.center.lat]);
    }

    if (this.options.zoom) {
      this.map.setZoom(this.options.zoom);
    }

    this.questMarkers.forEach(value => value.remove());
    this.questMarkers.clear();


    if (this.options.quests) {
      for (const quest of this.options.quests) {
        this.drawQuestMarker(quest, this.questMarkers, true);
      }
    }

    this.clusterMarkers.forEach(value => value.remove());
    this.clusterMarkers.clear();

    if (this.options.clusters) {
      for (const cluster of this.options.clusters) {
        this.drawClusterMarker(cluster, this.clusterMarkers, true, (cluster) => {
          this.clickOnCluster.emit(cluster);
        });
      }
    }

    if (this._options.numberedMarkers) {
      for (let i = 0; i < this._options.numberedMarkers.length; i++) {
        const marker = this._options.numberedMarkers[i];
        const text = marker.data?.['number'] ?? `${i + 1}`;
        this.numberedMarkers.set(marker.id, drawStaticMarker(marker.coords, 'with-text', {text}).addTo(this.map));
        this.allCoordinates.push(marker.coords);
      }
    }

    if (this._options.obelisks) {
      for (const obelisk of this._options.obelisks) {
        this.markers.push(drawObelisk(obelisk, this.map, () => this.clickOnObelisk.emit(obelisk)));
        this.allCoordinates.push(obelisk.location);
      }
    }

    if (this._options.markers) {
      for (const marker of this._options.markers) {
        const m = drawStaticMarker(marker.location, 'with-text', {text: '!'}, () => this.clickOnMarker.emit(marker));
        this.markers.push(m);
        m.addTo(this.map)
        this.allCoordinates.push(marker.location);
      }
    }

    if (this._options!.markerFindZones.length) {
      const circles = [];

      for (const zone of this._options!.markerFindZones) {
        if (!this._options!.hideMarkerInMarkerFindZones) {
          this.markers.push(drawMarkerOnMap(zone.coords, this.map));
        }

        const circle = turf.circle([zone.data.circleCenter.lng, zone.data.circleCenter.lat],
          zone.data.radius, {steps: 60, units: 'meters'});
        circles.push(circle);

        this.allCoordinates.push(zone.coords);
      }
      (this.map.getSource('task-zone') as mapboxgl.GeoJSONSource).setData(
        {
          type: 'FeatureCollection',
          features: circles
        }
      );
      (this.map.getSource('task-zone-outline') as mapboxgl.GeoJSONSource).setData(
        {
          type: 'FeatureCollection',
          features: circles
        }
      );

      //IDK if we need to add the coordinates for the task zone to allCoordinates. Not a lot of value from it
    } else if (this._options!.markerReach) {
      this.markers.push(drawMarkerOnMap(this._options!.markerReach.coords, this.map));
      this.allCoordinates.push(this._options!.markerReach.coords);
    }

    if (this._options!.startZone) {
      (this.map.getSource('start-zone-outline') as mapboxgl.GeoJSONSource).setData(
        {
          type: 'FeatureCollection',
          features: [zoneToFeatureLineString(this._options!.startZone, true)]
        }
      );

      (this.map.getSource('start-zones') as mapboxgl.GeoJSONSource).setData(
        {
          type: 'FeatureCollection',
          features: [zoneToFeaturePolygon(this._options!.startZone)]
        }
      );

      this.allCoordinates.push(...getAllCoordinatesFromZone(this._options!.startZone));
    }

    if (this._options!.hazardZones) {
      const outlineFeatures = [];
      const fillFeatures = [];
      for (const hz of this._options!.hazardZones) {
        outlineFeatures.push(zoneToFeatureLineString(hz, true));
        fillFeatures.push(zoneToFeaturePolygon(hz));

        this.allCoordinates.push(...getAllCoordinatesFromZone(hz));
      }
      (this.map.getSource('hazard-zones-outline') as mapboxgl.GeoJSONSource).setData(
        {
          type: 'FeatureCollection',
          features: outlineFeatures
        }
      );

      (this.map.getSource('hazard-zones') as mapboxgl.GeoJSONSource).setData(
        {
          type: 'FeatureCollection',
          features: fillFeatures
        }
      );

      if (!this.options.ignoreFitBounds) {
        if (this.allCoordinates.length > 1) {
          const coordinates: LngLatLike[] = this.allCoordinates.map(value => [value.lng, value.lat]);

          const bounds = new mapboxgl.LngLatBounds(
            coordinates[0],
            coordinates[0]
          );

          for (const coord of coordinates) {
            bounds.extend(coord);
          }

          this.map.fitBounds(bounds, {
            padding: 30
          });
        } else {
          this.map.setCenter([this.allCoordinates[0].lng, this.allCoordinates[0].lat]);
        }
      }
    }
  }

  protected drawQuestMarker(quest: QuestMetadata, collection: Map<number, mapboxgl.Marker>, visible: boolean): void {
    if (!this.map) {
      return;
    }

    let difficulty = quest.info.difficulty?.toLowerCase();
    if (!difficulty) {
      difficulty = 'medium';
    }
    //
    // if (isQuestBlockedByLevel(quest, this.user)) {
    //   path = 'assets/gameplay-icons/quest-marker-locked.svg';
    // }
    const el = document.createElement('div');
    let insideCircle: string;
    if (quest.isDaily) {
      const svgPath = `assets/project-icons/sun.svg#c`;
      insideCircle = `<svg style="width: 20px; height: 20px" viewBox="0 0 24 24"><use xlink:href="${svgPath}"></use></svg>`
    } else if (quest.info.images.length <= 1) {
      const rf = quest.info.recommendedFor ?? 'walk'
      const svgPath = `assets/project-icons/${rf}-20.svg#c`;

      insideCircle = `<svg style="width: 24px; height: 24px" viewBox="0 0 20 20"><use xlink:href="${svgPath}"></use></svg>`
    } else {
      insideCircle = `<img src="${quest.info.images[0].url}" alt="NO IMG" class="rescaled-image">`
    }

    let innerHTML = `
<div class="main-circle">${insideCircle}</div>
<div class="pin"></div>
    `;

    if (quest.organization) {
      innerHTML += `<div class="organization" style="background: ${quest.organization.primaryColor}">`;

      if (quest.organization.secondaryLogo.startsWith('http')) {
        innerHTML += `<img src="${quest.organization.secondaryLogo}" alt="NO IMG" class="inner-img">`;
      } else {
        innerHTML += `<div class="inner-img">${quest.organization.secondaryLogo}</div>`;
      }

      innerHTML += `</div>`;
    }

    el.innerHTML = innerHTML;
    el.className = 'map-quest-marker-container ' + difficulty + (quest.isDaily ? ' daily' : '');
    el.style.display = (visible ? 'flex' : 'none');

    const marker = new mapboxgl.Marker(el).setLngLat(quest.locationPoint).addTo(this.map);

    marker.getElement().getElementsByClassName('main-circle')[0].addEventListener('click', (ev: any) => {
      // if (!isQuestBlockedByLevel(quest, this.user)) {
      //   this.selectQuest(quest);
      // }

      this.clickOnQuest.emit(quest);

      ev.stopPropagation();
    });

    collection.set(quest.id, marker);
  }

  private drawClusterMarker(cluster: Cluster, collection: Map<number, mapboxgl.Marker>, visible: boolean, onClick: (cluster: Cluster) => void): void {
    if (!this.map) {
      return;
    }

    const el = document.createElement('div');

    const count = cluster.itemsCount > 9 ? '9+' : cluster.itemsCount.toString();

    el.innerHTML = `<div class="count">${count}</div>`;
    el.className = 'cluster-marker';
    el.style.display = (visible ? 'flex' : 'none');

    const marker = new mapboxgl.Marker(el).setLngLat(cluster.center).addTo(this.map);

    el.addEventListener('click', (ev: any) => {
      // if (this.loadingQuests) {
      //   return;
      // }

      adjustMapBBox([cluster.bbox.max, cluster.bbox.min], this.map, 0);

      onClick(cluster);

      ev.stopPropagation();
    });

    collection.set(cluster.id, marker);
  }

  ngOnDestroy(): void {
    this.observer.unobserve(document.getElementById(`map-container-${this.id}`)!);
  }
}
