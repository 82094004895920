import {translateObject} from '@ngneat/transloco';
import {QuestAvailabilityTypes, QuestDifficulty} from '../interfaces/quest-info.enums';
import {datetimeToPrettyString, toEUDateString} from './date.functions';
import {QuestAvailability} from '../interfaces/quest-info.interfaces';

export const questAvailabilityToText = (availability: QuestAvailability): string => {
  if (!availability || !availability.type) {
    return translateObject('availability.always') as string;
  }

  switch (availability.type) {
    case QuestAvailabilityTypes.always:
      return translateObject('availability.always') as string;
    case QuestAvailabilityTypes.unavailable:
      return translateObject('availability.unavailable') as string;
    case QuestAvailabilityTypes.fromDate:
      return `${translateObject('availability.from')} ${toEUDateString(availability.data.fromDate)}`;
    case QuestAvailabilityTypes.toDate:
      return `${translateObject('availability.to')} ${toEUDateString(availability.data.toDate)}`;
    case QuestAvailabilityTypes.fromDateToDate:
      return `${toEUDateString(availability.data.fromDate)} -
        ${toEUDateString(availability.data.toDate)}`;
    case QuestAvailabilityTypes.expiresAt:
      return `${translateObject('availability.expiresAt')} ${datetimeToPrettyString(availability.data.expiresAt)}`;
    case QuestAvailabilityTypes.today:
      return translateObject('today') as string;
    default:
      return '';
  }
};

export const difficultyEnumToPrettyText = (difficulty: QuestDifficulty): string => {
  switch (difficulty) {
    case QuestDifficulty.easy: return translateObject('easy') as string;
    case QuestDifficulty.medium: return translateObject('medium') as string;
    case QuestDifficulty.hard: return translateObject('hard') as string;
    case QuestDifficulty.master: return translateObject('master') as string;
    default: return '';
  }
}
